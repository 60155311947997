/** @jsx jsx */
import { jsx } from "theme-ui"
import BlogListItem from "./blog-list-item"
import SectionBox from "./section-box"

type ListingProps = {
  posts: {
    slug: string
    title: string
    date: string
    excerpt: string
    description: string
    banner: string
    // bgImg?: string
    timeToRead?: number
    tags?: {
      name: string
      slug: string
    }[]
  }[]
  className?: string
  showTags?: boolean
}

const Listing = ({ posts, className, showTags = true }: ListingProps) => (
  <SectionBox sx={{ padding: `60px 100px 60px 100px` }} className={className}>
    {posts.map((post) => (
      <BlogListItem key={post.slug} post={post} showTags={showTags} />
    ))}
  </SectionBox>
)

export default Listing
