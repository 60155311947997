/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Box, Text, Flex, Link as TLink } from "@theme-ui/components"
import { Link } from "gatsby"
import ItemTags from "./item-tags"
import ButtonAccent from "./button-accent"

type BlogListItemProps = {
  post: {
    slug: string
    title: string
    date: string
    excerpt: string
    description: string
    timeToRead?: number
    tags?: {
      name: string
      slug: string
    }[]
    // bgImg: string
  }
  showTags?: boolean
}
const bgImg = `./post-default.jpg`

const BlogListItem = ({ post, showTags = true }: BlogListItemProps) => (
  <Box mb={4} sx={{ verticalAlign: `text-top` }}>
    <Box sx={{ width: `35vw`, height: `20vw`, backgroundImage: `url(`+ bgImg +`)`, backgroundSize: `cover`, border: `30px solid #2A2D34`, display: `inline-block`, verticalAlign: `text-top` }} />
    <Box sx={{ width: `45vw`, display: `inline-block`, paddingLeft: `60px`, verticalAlign: `text-top`}}>
      <TLink as={Link} to={post.slug} sx={{ fontSize: `1.8rem`, color: `#212121`, lineHeight: `2.7rem`, 
      fontFamily: `Montserrat`, textTransform: `Uppercase`, letterSpacing: `0.25rem`, display: `block`, mb: 5 }}>
        {post.title}
      </TLink>
      <Text sx={{ mb: 5, color: `#808080`, fontFamily: `OpenSans`, fontSize: `1rem`, lineHeight: `2rem` }}>
        {post.excerpt}
      </Text>
      <Box sx ={{ textAlign: `right`, mb: 6 }}>
        <ButtonAccent text="Full article" targetUrl={post.slug} />
      </Box>
    </Box>
  </Box>
)

export default BlogListItem
