/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link } from "@theme-ui/components"

type ButtonProps = {
  targetUrl: string
  text: string
}

const ButtonAccent = ({ text, targetUrl }: ButtonProps) => {
  return (
    <React.Fragment>
      <Link href={targetUrl} sx={{
        backgroundColor: `#F4E409`,
        borderRadius: `100px`,
        fontFamily: `Montserrat`,
        fontWeight: `700`,
        fontSize: `0.8rem`,
        textTransform: `Uppercase`,
        textDecoration: `none`,
        color: `#292929`,
        letterSpacing: `2.4px`,
        padding: `17px 36px 17px 36px`,
      }}>
        {text}
      </Link>
    </React.Fragment>
  )
}

export default ButtonAccent